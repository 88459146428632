import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CdnPathPipe } from '@uc/utilities';
import { PromoSlotsComponent } from './promo-slots/promo-slots.component';
import { PromoSlotCardComponent } from './promo-slots/promo-slot-card/promo-slot-card.component';

const components = [PromoSlotsComponent, PromoSlotCardComponent];

@NgModule({
	imports: [CdnPathPipe, RouterModule, CommonModule],
	declarations: components,
	exports: components,
})
export class WebSharedFeaturePromoSlotsModule {}
