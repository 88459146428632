import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PromoSlotService } from '../services/promo-slot.service';
import { PromoSlotComponentName, PromoSlotsUniversity } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-promo-slot-cta-card',
	standalone: true,
	imports: [NgOptimizedImage, RouterModule],
	templateUrl: './promo-slot-cta-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoSlotCtaCardComponent {
	description = input.required<string>();
	image = input.required<string>();
	link = input.required<string>();
	heading = input.required<string>();
	slotIndex = input.required<number>();
	isTracked = input<number>();
	uni = input<PromoSlotsUniversity>();

	constructor(private _promoSlotSrv: PromoSlotService) {}

	processLinks() {
		this._promoSlotSrv.handlePromoSlotClick(
			this.link(),
			PromoSlotComponentName.SIDEBAR,
			this.slotIndex(),
			null,
			this.uni()?.id,
			this.uni()?.name,
			this.isTracked(),
		);
	}
}
