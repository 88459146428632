<section class="bg-white divider">
	<div class="flex flex-col">
		<div class="relative overflow-hidden">
			<!-- cover photo with link -->
			<!-- Desktop -->
			<div class="hidden h-full md:flex">
				<!-- bookmark in special cases-->
				@if (enableSave && uniId && componentName) {
					<div class="relative w-1/2">
						<div class="absolute z-[45] h-10 top-4 right-4">
							<uc-bookmark
								[bookmarkHeartIcon]="true"
								[saved]="isSaved"
								[id]="uniId"
								[delta]="1"
								[componentName]="componentName"
								[product]="product"
								[ugSearchOrderPosition]="ugSearchOrderPosition"
								[uniName]="uniName"
							/>
						</div>
					</div>
				}
				<a
					[routerLink]="link"
					[queryParams]="qParams"
					(click)="onClick($event)"
					class="hidden md:flex"
				>
					<uc-engage-card-cta-img
						[backgroundImageClass]="backgroundImageClass"
						[backgroundImage]="backgroundImage"
						[border]="border"
					></uc-engage-card-cta-img>
				</a>
			</div>

			<!-- cover photo with link -->
			<!-- Mobile -->
			<!-- External Link -->
			@if (externalLink) {
				<a
					(click)="onClick($event)"
					[attr.href]="externalLink"
					target="_blank"
					rel="noopener sponsored"
					class="md:hidden"
				>
					<uc-engage-card-cta-img
						[backgroundImageClass]="backgroundImageClass"
						[backgroundImage]="backgroundImage"
						[border]="border"
					/>
				</a>
			} @else {
				<div class="relative md:hidden">
					@if (enableSave && uniId && componentName) {
						<div class="absolute z-10 top-4 right-4">
							<uc-bookmark
								[bookmarkHeartIcon]="true"
								[saved]="isSaved"
								[id]="uniId"
								[delta]="1"
								[componentName]="componentName"
								[product]="product"
								[ugSearchOrderPosition]="ugSearchOrderPosition"
								[uniName]="uniName"
							/>
						</div>
					}
					<a (click)="onClick($event)" [routerLink]="link" class="md:hidden">
						<uc-engage-card-cta-img
							[backgroundImageClass]="backgroundImageClass"
							[backgroundImage]="backgroundImage"
							[border]="border"
						/>
					</a>
				</div>
			}

			<!-- Internal Link -->

			<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
				<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
					<!-- icon & uppercase icon text -->
					@if (icon || iconText) {
						<div
							class="items-center gap-3"
							[ngClass]="{
								'hidden md:flex': hideIconOnMobile,
								flex: !hideIconOnMobile,
							}"
						>
							@if (icon) {
								<img
									[ngSrc]="'/assets/img/' + icon | cdn"
									width="20"
									height="20"
									alt=""
								/>
							}
							@if (iconText) {
								<p class="font-semibold text-gray-500 uppercase">
									{{ iconText }}
								</p>
							}
						</div>
					}

					<!-- title -->
					<p class="flex flex-wrap-reverse mt-2 tracking-tight">
						<!-- Desktop -->
						<a
							[routerLink]="link"
							[queryParams]="qParams"
							(click)="onClick($event)"
						>
							<span class="hidden text-3xl font-bold md:flex">
								{{ cardTitle }}
							</span>
						</a>
						<!-- Mobile -->
						<!-- External Link -->
						@if (externalLink) {
							<a
								(click)="onClick($event)"
								[attr.href]="externalLink"
								target="_blank"
								rel="noopener sponsored"
							>
								<span class="text-2xl font-bold md:hidden">
									{{ cardTitle }}
								</span>
							</a>
						} @else {
							<!-- Internal Link -->
							<a [routerLink]="link" (click)="onClick($event)">
								<span class="text-2xl font-bold md:hidden">
									{{ cardTitle }}
								</span>
							</a>
						}
					</p>

					<!-- description -->
					<p class="mt-4 text-base text-gray-500">
						{{ description }}
						<ng-content select="[leave-review]"></ng-content>
					</p>

					<!-- CTA button -->
					<div class="mt-8">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
