import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_PAGE_DATA_FEATURE_KEY, PageDataState } from './pages-api.reducer';

export const getPageDataState = createFeatureSelector<PageDataState>(
	_STATE_PAGE_DATA_FEATURE_KEY,
);

export const getPageData = createSelector(
	getPageDataState,
	(state: PageDataState) => state.pageData,
);
