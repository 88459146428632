import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LinkedinFooterSvgComponent, TiktokSvgComponent } from '@uc/shared/svg';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-social-and-app-cta',
	standalone: true,
	templateUrl: './social-and-app-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		NgOptimizedImage,
		TiktokSvgComponent,
		LinkedinFooterSvgComponent,
	],
})
export class SocialAndAppCtaComponent {}
