import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	ViewChild,
	afterNextRender,
} from '@angular/core';
import { CdnPathPipe, Environment } from '@uc/utilities';
import {
	FeaturedUndergraduate,
	FeaturedPostgraduate,
	FeaturedClearing,
	Gallery,
	Product,
} from '@uc/web/shared/data-models';

import { BookmarkComponent } from '../bookmark/bookmark.component';
import { ArrowLeftSvgComponent, ArrowRightSvgComponent } from '@uc/shared/svg';
import { VideoGTMService, ImpressionDirective } from '@uc/web/core';

@Component({
	selector: 'uc-university-gallery',
	standalone: true,
	templateUrl: './university-gallery.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		CommonModule,
		NgOptimizedImage,
		BookmarkComponent,
		ImpressionDirective,
		ArrowLeftSvgComponent,
		ArrowRightSvgComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UniversityGalleryComponent {
	@ViewChild('swiperRef') protected swiperRef: ElementRef | undefined;

	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) gallery!: Gallery;
	@Input({ required: true }) coverImage!: string;
	/**
	 * @id uniId | courseId
	 */
	@Input({ required: true }) id!: number;
	@Input({ required: true }) isFeatured!:
		| FeaturedUndergraduate
		| FeaturedPostgraduate
		| FeaturedClearing;
	// Inputs for bookmark component
	@Input({ required: true }) saved!: number | boolean;
	@Input({ required: true }) delta!: 1 | 2;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input({ required: true }) product!: Product;
	@Input() courseSlug = '';
	@Input() showBookmark = true;

	clearingEnabled!: boolean;
	index = 0;

	constructor(
		@Inject('environment') environment: Environment,
		private _videoGTMSrv: VideoGTMService,
		private _el: ElementRef,
	) {
		this.clearingEnabled = environment.clearingEnabled;
		afterNextRender(() => {
			this._initSwiper();
		});
	}

	swipePrev() {
		this.swiperRef?.nativeElement.swiper.slidePrev();
	}

	swipeNext() {
		this.swiperRef?.nativeElement.swiper.slideNext();
	}

	onVideoPlay() {
		this._videoGTMSrv.start(this.id, this.product);
	}

	onVideoEnded() {
		this._videoGTMSrv.completed(this.id, this.product);
	}

	getVideoFormat(video: string): string {
		const format = video.split('.').pop() || '';
		return `video/${format}`;
	}

	pauseVideoIfPlaying() {
		if (!this.gallery.video) return;
		const videos = this._el.nativeElement.querySelectorAll('.video-player');

		let dormantVideoIndex;
		videos.forEach((video: HTMLVideoElement, index: number) => {
			const { paused, currentTime, ended } = video;

			if (!paused && currentTime > 0 && !ended) {
				video.pause();
				dormantVideoIndex = index === 0 ? 1 : 0;
				videos[dormantVideoIndex].currentTime = currentTime;
			}
		});
	}

	private _initSwiper() {
		if (!this.isFeatured) return;
		const config = {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,
			pagination: {
				type: 'fraction',
			},
			navigation: false,
			on: {
				slideChange: () => {
					this.pauseVideoIfPlaying();
				},
			},
			injectStyles: [
				`
				.swiper-pagination {
					background-color: rgba(72, 70, 70, 0.562) !important;
					padding: 0.2rem 0.5rem !important;
					color: white !important;
					width: max-content !important;
					left: 50% !important;
					border-radius: 5px !important;
					transform: translateX(-50%) !important;
					user-select: none !important;
				}`,
			],
		};

		const swiperEl = this.swiperRef?.nativeElement;
		Object.assign(swiperEl, config);

		swiperEl.initialize();
	}
}
