import { gql } from 'graphql-tag';

export const GET_PROMO_SLOTS = gql`
	query getPromoSlots {
		promoSlots {
			id
			title
			description
			image
			link
		}
	}
`;

export const GET_CLEARING_PROMO_SLOTS = gql`
	query getClearingPromoSlots {
		clearingPromoSlots {
			id
			title
			description
			image
			link
			is_tracked
			university {
				id
				name
			}
		}
	}
`;
