@if (phoneNumber) {
	<a
		[attr.href]="'tel:' + phoneNumber"
		(click)="onCallUni()"
		[ngClass]="customStyles"
		class="inline-block call-uni-btn focus:outline-none whitespace-nowrap"
	>
		<ng-content></ng-content>
		<!-- show number on desktop -->
		<span class="hidden lg:block">{{ showNumber ? phoneNumber : buttonText }}</span>
		<!-- only show 'Call' on mobile-->
		<span class="lg:hidden">{{ mobileButtonText }}</span>
	</a>
}
