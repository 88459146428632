<!-- (mobile) -->
<a
	(click)="onRegisterInterestClick()"
	routerLink="/register-interest"
	[queryParams]="{ uni: uniId, source: sourceType }"
	[ngClass]="customStyles"
	class="inline-flex items-center justify-center register-interest-btn whitespace-nowrap"
>
	<span class="inline-flex sm:hidden">{{ mobileButtonText }}</span>
	<span class="hidden sm:inline-flex">{{ buttonText }}</span>
</a>
