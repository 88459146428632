import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginGTMService } from '@uc/web/core';
import { SocialAndAppCtaComponent } from './social-and-app-cta/social-and-app-cta.component';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-footer',
	standalone: true,
	templateUrl: './footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SocialAndAppCtaComponent, RouterModule],
})
export class FooterComponent {
	currentYear = new Date().getFullYear();

	constructor(private loginGTMSrv: LoginGTMService) {}

	// GTM event
	onLoginClick = () => this.loginGTMSrv.loginClick();
}
