import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { PromoSlot } from '@uc/web/shared/data-models';
import { addContentToArrays } from '../utils/promo-slots.utils';
import {
	fetchPromoSlots,
	getClePromoSlotsData,
	getUcPromoSlotsData,
} from '@uc/web/shared/data-access';
import { HelperService } from '@uc/utilities';

@Component({
	selector: 'uc-promo-slots',
	templateUrl: './promo-slots.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoSlotsComponent implements OnInit {
	@Input() headline = '';
	@Input() section: 1 | 2 = 1;
	@Input() type: 'clearing' | 'uc' = 'uc';
	@Input() isInAppBrowser = false;
	cards: PromoSlot[] = [];
	promoSlots$!: Observable<PromoSlot[] | null>;

	private _firstBatch: PromoSlot[] = [];
	private _secondBatch: PromoSlot[] = [];

	constructor(
		private _store: Store,
		private _helperSrv: HelperService,
	) {}

	ngOnInit(): void {
		if (this.section === 1) {
			this._store.dispatch(fetchPromoSlots());
		}

		const selector = this.type === 'uc' ? getUcPromoSlotsData : getClePromoSlotsData;

		this.promoSlots$ = this._store.select(selector).pipe(
			tap((cards) => {
				if (!cards || cards.length === 0) return;

				const copy: PromoSlot[] = this._helperSrv.deepCopyObject(cards);
				addContentToArrays(copy, 0, this._firstBatch);
				addContentToArrays(copy, 4, this._secondBatch);
				this.cards = this.section === 1 ? this._firstBatch : this._secondBatch;
			}),
		);
	}
}
