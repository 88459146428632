import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import {
	fetchClientDetails,
	fetchStudentDetails,
	loadUserDetailsError,
	updateClientDetails,
	updateStudentDetails,
} from './user-details.actions';
import { UserDetailsApiService } from '../user-details-api.service';
import { ClientDetails, StudentDetails } from '@uc/web/shared/data-models';
import { AuthenticationService } from '@uc/shared/authentication';

@Injectable()
export class StateUserDetailsEffect {
	constructor(
		private readonly _actions$: Actions,
		private _userDetailsApiSrv: UserDetailsApiService,
		private _authSrv: AuthenticationService,
	) {}

	fetchUserDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchStudentDetails),
			distinctUntilChanged(),
			switchMap(() => this._userDetailsApiSrv.getStudentDetails()),
			map((payload) =>
				updateStudentDetails({
					student: payload,
				}),
			),
			catchError((error) => {
				if (error.status === 401) {
					this._authSrv.signOut();
				}
				return of(loadUserDetailsError({ error }));
			}),
		),
	);

	fetchClientDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchClientDetails),
			distinctUntilChanged(),
			switchMap(() => this._userDetailsApiSrv.getClientDetails()),
			map((payload) =>
				updateClientDetails({
					client: payload,
				}),
			),
			catchError((error) => {
				if (error.status === 401) {
					this._authSrv.signOut();
				}
				return of(loadUserDetailsError({ error }));
			}),
		),
	);
}
