import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product, ClientFeatureOptions, Gallery } from '@uc/web/shared/data-models';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { UniversityGalleryComponent } from '../university-gallery/university-gallery.component';
import { JsonPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-university-header',
	standalone: true,
	templateUrl: './university-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		NgClass,
		JsonPipe,
		NgOptimizedImage,
		BookmarkComponent,
		UniversityGalleryComponent,
	],
})
export class UniversityHeaderComponent {
	@Input({ required: true }) universityGallery!: Gallery;
	@Input({ required: true }) paidFeatures!: ClientFeatureOptions[];
	@Input({ required: true }) logo!: string;
	@Input({ required: true }) uniName!: string;
	@Input() product!: Product;
	@Input() saved: number | undefined | boolean;
	// University ID | Course ID
	@Input() id: number | undefined;
	@Input() page = 'university';
	@Input() currentClearingYear!: number;
	/*
	 * delta takes the values 1 | 2
	 * 1 = save university
	 * 2 = save course
	 */
	@Input() delta!: 1 | 2;
	@Input() courseSlug = '';
	@Input() isLoaded!: boolean;
	@Input() clearingCourseEnabled!: boolean;
	@Input() ugSearchOrderPosition!: number | null;
}
