<!-- mobile -->
<a
	[href]="link"
	class="inline-block w-full md:w-auto lg:hidden"
	[ngClass]="customStyles"
	target="_blank"
	rel="noopener"
	>{{ buttonText }}</a
>

<!-- desktop -->
<button
	class="hidden blue-button lg:block"
	[ngClass]="customStyles"
	(click)="openModal()"
>
	{{ buttonText }}
</button>
