import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CallUniBtnApiService } from './call-uni-btn-api.service';
import { CallUniComponentName, SourceTrackingTypes } from '@uc/web/shared/data-models';
import { CallUniversityGTMService, ConversionEventsService } from '@uc/web/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-call-uni-btn',
	standalone: true,
	templateUrl: './call-uni-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class CallUniBtnComponent {
	@Input({ required: true }) phoneNumber = '';
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName = '';
	@Input({ required: true }) source = '';
	@Input({ required: true }) sourceType!: SourceTrackingTypes;
	@Input({ required: true }) componentName!: CallUniComponentName;
	@Input() buttonText = 'Call';
	@Input() mobileButtonText = '';
	@Input() customStyles = '';

	showNumber = false;

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _callUniBtnApiSrv: CallUniBtnApiService,
		private _callUniGTMSrv: CallUniversityGTMService,
	) {}

	onCallUni() {
		this.showNumber = true;
		this._conversionEventsSrv.callUni();

		this._callUniBtnApiSrv.trackClick(this.uniId, this.source, this.sourceType);
		this._callUniGTMSrv.click(this.uniId, this.uniName, this.componentName);
	}
}
