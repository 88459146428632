import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	FeaturedCalls,
	FeaturedClearingVisitWebsite,
	FeaturedRegisteredInterest,
	Product,
} from '@uc/web/shared/data-models';
import { VisitWebsiteBtnComponent } from '../../buttons/visit-website-btn/visit-website-btn.component';
import { CallUniBtnComponent } from '../../buttons/call-uni-btn/call-uni-btn.component';
import { RegisterInterestBtnComponent } from '../../buttons/register-interest-btn/register-interest-btn.component';
@Component({
	selector: 'uc-clearing-cta-final',
	standalone: true,
	templateUrl: './clearing-cta-final.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RegisterInterestBtnComponent,
		CallUniBtnComponent,
		VisitWebsiteBtnComponent,
	],
})
export class ClearingCtaFinalComponent {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName = '';
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) isFeaturedCalls!: FeaturedCalls;
	@Input({ required: true }) isFeaturedRegisterInterest!: FeaturedRegisteredInterest;
	@Input({ required: true })
	isFeaturedClearingVisitWebsite!: FeaturedClearingVisitWebsite;
	@Input({ required: true }) phoneNumber!: string;
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) currentClearingYear!: number;
	@Input({ required: true }) source!: string;
}
