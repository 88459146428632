import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { ConversionEventsService } from '@uc/web/core';
import { CommonModule } from '@angular/common';
import { SourceTrackingTypes, Product } from '@uc/web/shared/data-models';
import { VisitWebsiteBtnService } from './visit-website-btn.service';

@Component({
	selector: 'uc-visit-website-btn',
	standalone: true,
	templateUrl: './visit-website-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class VisitWebsiteBtnComponent {
	@Input({ required: true }) uniId!: number | null;
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) source!: string; // uni name or course name
	@Input({ required: true }) sourceType: SourceTrackingTypes = '';
	@Input({ required: true }) product: Product | null = null;
	@Input({ required: true }) externalUrl!: string | undefined;
	@Input() buttonText = 'Visit Website';
	@Input() customStyles = 'w-auto';
	@Input() componentName!: string;
	@Input() trackingEnabled = true;
	@Input() ugSearchOrderPosition!: number | null;
	@Output() visitWebsiteClick = new EventEmitter<void>();

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _visitWebsiteBtnSrv: VisitWebsiteBtnService,
	) {}

	// GTM Event
	onVisitWebsiteClick() {
		this._visitWebsiteBtnSrv.onVisitWebsiteClick(
			this.uniId,
			this.uniName,
			this.componentName,
			this.ugSearchOrderPosition,
			this.source,
			this.product,
			this.sourceType,
			this.trackingEnabled,
		);
	}

	// Google Ads Conversion
	onVisitWebsiteConversion() {
		this._conversionEventsSrv.visitWebsite();
	}
}
