import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { FilterDesktopSvgComponent, FilterMobileSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-show-filter-button',
	standalone: true,
	templateUrl: './show-filter-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, FilterDesktopSvgComponent, FilterMobileSvgComponent],
})
export class ShowFilterButtonComponent {
	@Input() length!: number;
	@Input() customWidth = 'w-auto';
	@Output() toggleFilter: EventEmitter<string> = new EventEmitter<string>();
}
