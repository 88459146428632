import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegisterInterestGTMService, ConversionEventsService } from '@uc/web/core';
import {
	RegisterInterestComponentName,
	SourceTrackingTypes,
} from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-register-interest-btn',
	standalone: true,
	templateUrl: './register-interest-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class RegisterInterestBtnComponent {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) sourceType: SourceTrackingTypes = '';
	@Input() componentName!: RegisterInterestComponentName;
	@Input() customStyles = 'w-full';
	@Input() buttonText = 'Register Interest';
	@Input() mobileButtonText = 'Register Interest';

	constructor(
		private conversionEventsSrv: ConversionEventsService,
		private registerInterestGTMSrv: RegisterInterestGTMService,
	) {}

	onRegisterInterestClick(): void {
		this.conversionEventsSrv.clickRegisterInterest();
		this.registerInterestGTMSrv.click(this.componentName);
	}
}
