import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { EngageCardCtaImgComponent } from './components/engage-card-cta-img/engage-card-cta-img.component';
import { CdnPathPipe } from '@uc/utilities';
import { BookmarkComponent } from '../../bookmark/bookmark.component';
import { Product } from '@uc/web/shared/data-models';
@Component({
	selector: 'uc-engage-card-cta',
	standalone: true,
	templateUrl: './engage-card-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		NgOptimizedImage,
		RouterModule,
		CommonModule,
		EngageCardCtaImgComponent,
		BookmarkComponent,
	],
})
export class EngageCardCtaComponent {
	@Input() backgroundImage!: string;
	@Input() backgroundImageClass!: string;
	@Input() icon!: string;
	@Input() iconText!: string;
	@Input() hideIconOnMobile = false;
	@Input() border = false;
	@Input() cardTitle!: string;
	@Input() description!: string;
	@Input() link = '';
	@Input() qParams!: { [key: string]: string | number };
	@Input() externalLink = '';
	@Input() sectionTitle = '';

	@Input() uniId!: number;
	@Input() uniName!: string;
	@Input() isSaved = 0;
	@Input() componentName = '';
	@Input() enableSave = false;
	@Input() product: Product | '' = '';
	@Input() ugSearchOrderPosition!: number | null;

	@Output() ctaClicked = new EventEmitter<Event>();

	onClick(event: Event) {
		this.ctaClicked.emit(event);
	}
}
