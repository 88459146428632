import { Injectable } from '@angular/core';
import { VisitWebsiteGTMService } from '@uc/web/core';
import { VisitWebsiteBtnApiService } from '@uc/web/shared/data-access';
import { Product, ProductEnum, SourceTrackingTypes } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class VisitWebsiteBtnService {
	constructor(
		private _visitWebsiteGTMSrv: VisitWebsiteGTMService,
		private _visitWebsiteApiSrv: VisitWebsiteBtnApiService,
	) {}

	onVisitWebsiteClick(
		uniId: number | null,
		uniName: string,
		componentName: string,
		ugSearchOrderPosition: number | null,
		source: string,
		product: Product | null,
		sourceType: SourceTrackingTypes = '',
		trackingEnabled = true,
	) {
		if (uniId) {
			this._visitWebsiteGTMSrv.visitWebsiteClick(
				uniId,
				uniName,
				componentName,
				ugSearchOrderPosition,
				product ?? ProductEnum.Undergraduate,
			);

			if (trackingEnabled) {
				this._visitWebsiteApiSrv.trackClick(
					uniId,
					source,
					product ?? ProductEnum.Undergraduate,
					sourceType,
				);
			}
		}
	}
}
