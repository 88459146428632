import { Injectable, makeStateKey } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	withLatestFrom,
	distinctUntilChanged,
	filter,
	switchMap,
	map,
	catchError,
	of,
	forkJoin,
} from 'rxjs';

import { getPromoSlotsData } from './promo-slots.selectors';
import { PromoSlot } from '@uc/web/shared/data-models';
import { PromoSlotsApiService } from '../promo-slots-api.service';
import {
	fetchPromoSlots,
	loadPromoSlotsError,
	updatePromoSlots,
} from './promo-slots.actions';
import { TransferStateService } from '@uc/utilities';

const TS_UC_PROMO_SLOTS = makeStateKey<PromoSlot[]>('ts-uc-promo-slots');
const TS_CLE_PROMO_SLOTS = makeStateKey<PromoSlot[]>('ts-cle-promo-slots');

@Injectable()
export class StatePromoSlotsEffect {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _tsSrv: TransferStateService,
		private _promoSlotApiSrv: PromoSlotsApiService,
	) {}

	fetchPromoSlots = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchPromoSlots),
			withLatestFrom(this._store.select(getPromoSlotsData)),
			distinctUntilChanged(),
			filter(([, cards]) => cards.uc === null && cards.clearing === null),
			switchMap(() => {
				return forkJoin({
					uc: this._tsSrv.getData<PromoSlot[]>(
						TS_UC_PROMO_SLOTS,
						this._promoSlotApiSrv.getUcPromoSlots(),
					),
					cle: this._tsSrv.getData<PromoSlot[]>(
						TS_CLE_PROMO_SLOTS,
						this._promoSlotApiSrv.getClePromoSlots(),
					),
				});
			}),
			map(({ uc, cle }) =>
				updatePromoSlots({
					ucSlots: uc,
					cleSlots: cle,
				}),
			),
			catchError((error) => of(loadPromoSlotsError({ error }))),
		),
	);
}
