import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	CourseGTMService,
	ImpressionDirective,
	ClientValidatorService,
	UniversityGTMService,
} from '@uc/web/core';
import {
	ClientFeatureOptions,
	ClientFeatures,
	FeaturedClearing,
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	Product,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { CdnPathPipe } from '@uc/utilities';
import { Params, RouterModule } from '@angular/router';
import { CtaClearingUniversityBtnsComponent } from '../cta-clearing-university-btns/cta-clearing-university-btns.component';
import { CtaUniversityBtnsComponent } from '../cta-university-btns/cta-university-btns.component';
import { SecondaryBlueBtnComponent } from '../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';
import { WriteReviewBtnComponent } from '../buttons/write-review-btn/write-review-btn.component';

@Component({
	selector: 'uc-university-card',
	standalone: true,
	templateUrl: './university-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		VisitWebsiteBtnComponent,
		SecondaryBlueBtnComponent,
		CtaUniversityBtnsComponent,
		CtaClearingUniversityBtnsComponent,
		BookmarkComponent,
		ImpressionDirective,
		NgOptimizedImage,
		RouterModule,
		WriteReviewBtnComponent,
	],
})
export class UniversityCardComponent implements OnInit {
	@Input({ required: true }) name!: string;
	@Input({ required: true }) id!: number;
	@Input({ required: true }) slug!: string;
	@Input({ required: true }) cover!: string;
	@Input({ required: true }) logo!: string;
	@Input({ required: true }) product: Product | null = null;
	@Input({ required: true }) clientFeatures!: ClientFeatureOptions[];
	@Input({ required: true }) recommended!: number | undefined;
	@Input({ required: true }) saved!: boolean;
	@Input({ required: true }) displaySeeCoursesBtn!: boolean;
	@Input() source = '';
	@Input() externalUrl!: string;
	@Input() searchTerm = '';
	@Input() displaySearchTerm = false;
	@Input() externalUgProspectusUrl: string | null = null;
	@Input() externalPgProspectusUrl: string | null = null;
	@Input() privacyPolicyUrl = '';
	@Input() phoneNumber!: string;
	@Input() ugSearchOrderPosition!: number | null;
	isFeatured!: FeaturedUndergraduate | FeaturedPostgraduate | FeaturedClearing;
	internalUniUrl!: string;
	queryParams: Params = {};

	readonly paidFeatures = [ClientFeatures.Undergraduate, ClientFeatures.Postgraduate];
	readonly clientFeatureOptions = ClientFeatures;

	constructor(
		public cvSrv: ClientValidatorService,
		private _uniGTMSrv: UniversityGTMService,
		private _courseGTMSrv: CourseGTMService,
	) {}

	ngOnInit(): void {
		if (this.product) {
			this.isFeatured = this.cvSrv.checkFeature(
				this.clientFeatures,
				ClientFeatures[this.getFeatureKey(this.product)],
			);
		} else {
			this.isFeatured = this.cvSrv.checkFeature(
				this.clientFeatures,
				this.paidFeatures,
			);
		}

		this.internalUniUrl = this.getUniUrl();
		this.prepareQueryParams();
	}

	getUniUrl(): string {
		if (this.product === ProductEnum.Clearing) {
			return `/universities/${this.slug}/clearing`;
		} else if (this.isPostgraduateOnly()) {
			return `/universities/${this.slug}/postgraduate`;
		} else if (this.product && this.product !== ProductEnum.Undergraduate) {
			return `/universities/${this.slug}/${this.product}`;
		} else {
			return `/universities/${this.slug}`;
		}
	}

	isPostgraduateOnly(): boolean {
		return (
			!this.clientFeatures?.includes('UNDERGRADUATE_PROFILE') &&
			this.clientFeatures?.includes('POSTGRADUATE_PROFILE')
		);
	}

	prepareQueryParams() {
		if (this.source === 'universities') {
			this.queryParams = {
				uni: this.slug,
			};
		} else {
			this.queryParams = {
				...(this.searchTerm && { s: this.searchTerm }),
				uni: this.slug,
			};
		}
	}

	getFeatureKey(str: string) {
		return (str.charAt(0).toUpperCase() +
			str.slice(1)) as keyof typeof ClientFeatures;
	}

	// GTM event
	onUniProfileClick() {
		this._uniGTMSrv.uniProfileClick(
			this.id,
			this.name,
			this.ugSearchOrderPosition,
			this.product ?? 'undergraduate',
		);
	}

	onUniImpression(impression: any) {
		if (impression) {
			this._uniGTMSrv.uniImpression(
				this.id,
				this.name,
				this.ugSearchOrderPosition,
				this.product ?? 'undergraduate',
			);
		}
	}

	/*
	   GTM Event when the See all courses button is clicked.
	   Should work when either a search term has been entered or no search term
	   has been entered.
	*/
	onViewMoreCourses() {
		this._courseGTMSrv.viewMore(this.id, this.name, this.searchTerm);
	}
}
