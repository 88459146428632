import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchComponent } from '@uc/shared/ui';
import { Product } from '@uc/web/shared/data-models';
import { InAppBrowserSearchBarComponent } from '../in-app-browser-search-bar/in-app-browser-search-bar.component';

@Component({
	selector: 'uc-course-search-banner',
	standalone: true,
	templateUrl: './course-search-banner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SearchComponent, InAppBrowserSearchBarComponent],
})
export class CourseSearchBannerComponent {
	@Input() product: Product = 'undergraduate';
	@Input() customHeading = '';
	@Input() description!: string;
	@Input() uniName!: string;
	@Input() uniSlug!: string;
	@Input() openSearchInNewTab = false;
	@Input() isInAppBrowser = false;
}
