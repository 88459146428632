<div
	(click)="processLinks()"
	class="block p-3 border cursor-pointer bg-uc-blue-50 border-uc-blue-200 rounded-2xl"
>
	<span class="block mb-3 text-sm font-medium leading-5 text-uc-blue-900"
		>Recommended University</span
	>
	@if (image()) {
		<span class="relative block w-full mb-5 h-52">
			<img class="object-cover rounded-2xl" [ngSrc]="image()" alt="" fill />
		</span>
	}
	<span class="block mb-1 text-lg font-bold leading-7 text-uc-blue-900">{{
		heading()
	}}</span>
	<span class="block text-base font-normal leading-6 text-gray-500">{{
		description()
	}}</span>
</div>
