import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PromoSlot, PromoSlotComponentName } from '@uc/web/shared/data-models';
import { PromoSlotService } from '../../services/promo-slot.service';
import { InAppBrowserMessageHandlerService } from '@uc/web/core';

@Component({
	selector: 'uc-promo-slot-card',
	templateUrl: './promo-slot-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoSlotCardComponent {
	@Input({ required: true }) card!: PromoSlot;
	@Input({ required: true }) cardIndex!: number;
	@Input({ required: true }) section!: 1 | 2;
	@Input() customHeight = 'lg:h-44';
	@Input() isInAppBrowser = false;

	constructor(
		private _promoSlotSrv: PromoSlotService,
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	processLinks() {
		this._promoSlotSrv.handlePromoSlotClick(
			this.card.link,
			PromoSlotComponentName.ROW,
			this.cardIndex,
			this.section,
			this.card.university?.id,
			this.card.university?.name,
			this.card.is_tracked,
			this.isInAppBrowser,
		);

		if (this.isInAppBrowser) {
			this._inAppBrowserMessageHandlerSrv.ios.onPromoSlot(this.card.link);
		}
	}
}
