import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UniversityGTMService, ConversionEventsService } from '@uc/web/core';
import { ToastrService } from 'ngx-toastr';
import { BookmarkType } from './bookmark.component';
import { BookmarkModel } from './bookmark.models';
import { BookmarkApiService } from './data-access/bookmark-api.service';
import { CustomCookieService, Role } from '@uc/shared/authentication';
import { updateSavedInStore } from './data-access/+state/bookmark.actions';
import { NotLoggedinModalComponent } from '../modals/not-loggedin-modal/not-loggedin-modal.component';
import { UniNotAllowedModalComponent } from '../modals/uni-not-allowed-modal/uni-not-allowed-modal.component';
import { ModalService } from '@uc/shared/ui';
@Injectable({
	providedIn: 'root',
})
export class BookmarkService {
	constructor(
		private _store: Store,
		private _toastr: ToastrService,
		private _modalSrv: ModalService,
		private _uniGTMSrv: UniversityGTMService,
		private _conversionEventsSrv: ConversionEventsService,
		private _bookmarkApiSrv: BookmarkApiService,
		private _cookieSrv: CustomCookieService,
	) {}

	async bookmarkHandler(args: BookmarkModel): Promise<boolean> {
		const userId = this._cookieSrv.get('user_id');
		const role = this._cookieSrv.get('role');

		if (role === Role.CLIENT) {
			this._modalSrv.setContent(UniNotAllowedModalComponent);
			this._modalSrv.show();
			return false;
		}
		if (!userId) {
			this._modalSrv.setContent(NotLoggedinModalComponent);
			this._modalSrv.show();
			return false;
		}

		if (!args.id) {
			this._toastr.show('error', 'Something went wrong!');
			return false;
		}

		try {
			if (args.saved === 0 || args.saved === false) {
				await this._save(args.id, args.delta, args.courseSlug);
				this._handleSaveResponse(args);

				return true;
			}

			await this._remove(args.id, args.delta, args.courseSlug);
			this._handleRemoveResponse(+args.id, args.delta);

			return true;
		} catch (error) {
			console.error(error);
			this._toastr.show('error', 'Something went wrong!');

			return false;
		}
	}

	private _handleSaveResponse(args: BookmarkModel): void {
		if (args.id && args.delta === 1) {
			this._store.dispatch(updateSavedInStore({ saved: true, uniId: +args.id }));
			// GTM Event
			this._uniGTMSrv.saveUniClick(
				args.componentName,
				+args.id,
				args.uniName,
				args.ugSearchOrderPosition,
				args.product,
			);
			// Google Ads Conversion
			this._conversionEventsSrv.saveUni();
		} else if (args.delta === 2) {
			// Google Ads Conversion
			this._conversionEventsSrv.saveCourse();
		}
		this._toastr.show('', `${BookmarkType[args.delta - 1]} saved!`);

		return;
	}

	private _handleRemoveResponse(id: number | undefined, delta: 1 | 2): void {
		if (id && delta === 1) {
			this._store.dispatch(updateSavedInStore({ saved: false, uniId: id }));
		}
		this._toastr.show('error', `${BookmarkType[delta - 1]} unsaved!`);

		return;
	}

	private _save(id: number, type: number, courseSlug: string) {
		if (type === 2 && courseSlug) {
			return this._bookmarkApiSrv.saveCourse(courseSlug);
		}
		return this._bookmarkApiSrv.saveUni(+id);
	}

	private _remove(id: number, type: number, courseSlug: string) {
		if (type === 2 && courseSlug) {
			return this._bookmarkApiSrv.deleteCourse(courseSlug);
		}
		return this._bookmarkApiSrv.deleteUni(+id);
	}
}
