import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { HeadingComponent } from '@uc/shared/ui';
import { ToggleFaqDirective } from '@uc/utilities';
import { JsonLdSchemaService, LinkProcessorService } from '@uc/web/core';
import { FAQ } from '@uc/web/shared/data-models';
import { ArrowUpSvgComponent, ArrowDownSvgComponent } from '@uc/shared/svg';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';

@Component({
	selector: 'uc-faq-accordion',
	standalone: true,
	templateUrl: './faq-accordion.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ToggleFaqDirective,
		HeadingComponent,
		CommonModule,
		ArrowUpSvgComponent,
		ArrowDownSvgComponent,
		VisitWebsiteBtnComponent,
	],
})
export class FaqAccordionComponent implements OnInit, OnDestroy {
	@Input() heading = 'FAQs';
	@Input({ required: true }) faq: FAQ[] = [];
	@Input() ngContentInserted = false;
	@Input() customStyles = 'pt-16 mb-16';
	@Input() uniName!: string;
	@Input() uniId!: number;
	@Input() ugSearchOrderPosition!: number | null;

	constructor(
		private _jsonLdSrv: JsonLdSchemaService,
		private _linkProcessorSrv: LinkProcessorService,
	) {}

	ngOnInit(): void {
		this._jsonLdSrv.addFaqSchema(this.faq);
	}

	ngOnDestroy(): void {
		this._jsonLdSrv.removeSchema('faq-schema');
	}

	processLinks = (e: Event) => this._linkProcessorSrv.processLinks(e);
}
