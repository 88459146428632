import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_PROMO_SLOTS_FEATURE_KEY, State } from './promo-slots.reducer';

export const getPromoSlotsState = createFeatureSelector<State>(
	_STATE_PROMO_SLOTS_FEATURE_KEY,
);

export const getPromoSlotsData = createSelector(getPromoSlotsState, (state: State) => ({
	uc: state.ucPromoSlots,
	clearing: state.clearingPromoSlots,
}));

export const getUcPromoSlotsData = createSelector(
	getPromoSlotsState,
	(state: State) => state.ucPromoSlots,
);

export const getClePromoSlotsData = createSelector(
	getPromoSlotsState,
	(state: State) => state.clearingPromoSlots,
);
