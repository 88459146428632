<!-- Article Header -->
<div class="pt-12 pb-12 lg:pb-0">
	<div class="container justify-start">
		<!-- loading animation -->
		@if (loadingArticle) {
			<div class="py-12">
				<uc-loading-animation [htmlString]="loadingAnimation" />
			</div>
		}
		@if (article) {
			<div class="grid grid-cols-1 lg:gap-12 lg:grid-cols-3">
				<!-- left panel -->
				<article class="col-span-2 text-base">
					<!-- article header -->
					<header class="pb-6 border-b sm:pb-10">
						<a
							[routerLink]="articleCategoryUrl"
							class="block mb-4 font-semibold tracking-wide uppercase blue-link"
						>
							{{ categoryKey || guideCategoryLongName | categoryName }}
						</a>
						<h1
							class="block mt-2 mb-4 text-3xl font-bold leading-8 tracking-tight"
						>
							{{ article.title | ucEntities }}
						</h1>
						<div class="mb-6 text-gray-500 sm:flex">
							@if (article.author) {
								<span>
									<span class="hidden sm:inline">By</span>
									{{ article.author }}
								</span>
							}
							@if (article.date) {
								<span>
									@if (article.author) {
										&nbsp;&middot;
									}
									{{ article.date | ucdate }}
								</span>
							}
							@if (article.reading_time > 0) {
								<span
									>&nbsp;&middot; {{ article.reading_time }} min
									<span class="hidden sm:inline">read</span></span
								>
							}
						</div>
						@if (article.quote) {
							<p
								class="my-6 text-base text-gray-500"
								[innerHTML]="article.quote"
							></p>
						}
						@if (article?.title) {
							<uc-share-article
								[title]="article.title"
								[seoDesc]="article.seodesc"
								[imgName]="article.imgname"
								[url]="url"
							/>
						}
						<div class="relative w-full h-52 sm:h-80">
							<img
								[attr.src]="article.imgname | cdn"
								class="object-cover w-full rounded-md shadow-sm h-52 sm:h-80"
								[attr.alt]="
									article.imgdesc ? article.imgdesc : article.title
								"
							/>
						</div>
					</header>
					<!-- content -->
					@if (article.content) {
						<div
							(click)="processLinks($event)"
							class="mb-12 text-gray-500 article"
							[innerHTML]="article.content | safe"
						></div>
					}
					<!-- promo slot -->
					@if (promoSlot) {
						<div class="divider-top-border lg:hidden">
							<uc-promo-slot-cta-card
								[description]="promoSlot.description"
								[image]="promoSlot.image"
								[link]="promoSlot.link"
								[heading]="promoSlot.title"
								[slotIndex]="promoSlot.index!"
							/>
						</div>
					}
				</article>
				<!-- right panel -->
				<aside
					class="w-full col-span-1 pt-16 border-t lg:pt-0 lg:block lg:border-none"
				>
					@if (featuredArticles?.popular_article?.length > 0) {
						<div>
							<h2 class="mb-6 text-lg font-semibold">Popular Advice</h2>
							@for (
								popular of featuredArticles.popular_article;
								track popular
							) {
								<div class="mb-6">
									<uc-side-card
										[article]="popular"
										[categoryUrl]="articleCategory"
									/>
								</div>
							}
						</div>
					}
					@if (article.articles && article.articles?.length > 0) {
						<div>
							<h2 class="pt-8 mb-6 text-lg font-semibold">
								Related Articles
							</h2>
							@for (related of article.articles; track related) {
								<div class="mb-6">
									<uc-side-card
										[article]="related"
										[categoryUrl]="articleCategory"
									/>
								</div>
							}
						</div>
					}
					<!-- promo slot -->
					@if (promoSlot) {
						<div class="hidden lg:block lg:sticky lg:left-0 lg:top-8">
							<uc-promo-slot-cta-card
								[description]="promoSlot.description"
								[image]="promoSlot.image"
								[link]="promoSlot.link"
								[heading]="promoSlot.title"
								[slotIndex]="promoSlot.index!"
							/>
						</div>
					}
				</aside>
			</div>
		}
	</div>
</div>
