<div class="relative inline w-auto sm:border-0 sm:gap-4 sm:block">
	<button
		(click)="toggleFilter.emit()"
		[ngClass]="customWidth"
		class="inline-flex justify-center p-3 border rounded-full cursor-pointer sm:justify-start focus:outline-none"
	>
		<!-- icon for desktop -->
		<uc-filter-desktop-svg
			class="hidden text-gray-500 sm:inline"
		></uc-filter-desktop-svg>
		<!-- icon for mobile -->
		<uc-filter-mobile-svg class="sm:hidden"></uc-filter-mobile-svg>
		<p class="ml-1 text-sm font-bold text-gray-900 pointer-events-none">
			Filter
			@if (length > 0) {
				({{ length }})
			}
		</p>
	</button>
</div>
