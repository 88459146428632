<div class="sm:border sm:rounded-lg sm:py-6 sm:px-5">
	<div
		ucImpression
		[count]="true"
		(onImpression)="onUniImpression($event)"
		class="sm:flex sm:items-center"
	>
		<!-- Mobile: Cover image -->
		<div class="relative w-full h-40 rounded-lg sm:hidden">
			<div class="absolute z-10 sm:hidden top-4 right-4">
				<uc-bookmark
					[saved]="saved"
					[id]="id"
					[delta]="1"
					[bookmarkHeartIcon]="true"
					componentName="University Listing"
					[ugSearchOrderPosition]="ugSearchOrderPosition"
					[uniName]="name"
				/>
			</div>
			<img
				[ngSrc]="cover | cdn"
				fill
				[attr.alt]="'Cover image of ' + name"
				class="object-cover w-full h-full rounded-xl"
			/>
		</div>

		<!-- Desktop: Logo -->
		<a
			(click)="onUniProfileClick()"
			[routerLink]="internalUniUrl"
			class="hidden z-[-1] pr-5 sm:inline-block"
		>
			<span class="relative block w-16 h-16">
				<img
					class="object-contain border rounded-lg"
					[ngSrc]="logo | cdn"
					fill
					[attr.alt]="'Logo of ' + name"
				/>
			</span>
		</a>

		<div class="flex items-center justify-between w-full pt-5 sm:pt-0">
			<div>
				<h2 class="text-xl font-bold text-black">
					<a (click)="onUniProfileClick()" [routerLink]="internalUniUrl">{{
						name
					}}</a>
				</h2>
				@if (recommended && recommended > 0) {
					<p class="mr-1 text-sm text-gray-500">
						{{ recommended }}% Recommended
					</p>
				} @else {
					<uc-write-review-btn
						[universityId]="id"
						[universityName]="name"
						[universityUrl]="slug"
						buttonText="Leave a review"
					/>
				}
			</div>

			<!-- Mobile -->
			@if (isFeatured) {
				<uc-visit-website-btn
					customStyles="w-full sm:hidden py-2.5"
					[externalUrl]="externalUrl"
					product="undergraduate"
					componentName="University Listing"
					[uniId]="id"
					buttonText="Website"
					sourceType="uni"
					[source]="name"
					[ugSearchOrderPosition]="ugSearchOrderPosition"
					[uniName]="name"
				/>
			}
		</div>

		<!-- Desktop -->
		<div class="hidden gap-4 ml-2 sm:flex sm:items-center">
			@if (displaySeeCoursesBtn) {
				<uc-secondary-blue-btn
					(buttonClicked)="onViewMoreCourses()"
					[link]="product ? '/courses/' + product : '/courses/undergraduate'"
					[id]="slug"
					[queryParams]="queryParams"
					[buttonText]="
						source === 'universities' ? 'See Courses' : 'See More Courses'
					"
				/>
			}

			<uc-bookmark
				[saved]="saved"
				[id]="id"
				[delta]="1"
				[bookmarkHeartIcon]="true"
				componentName="University Listing"
				[ugSearchOrderPosition]="ugSearchOrderPosition"
				[uniName]="name"
			/>
		</div>
	</div>

	<!-- Inject dynamic content: Courses -->
	<div class="flex flex-col">
		<div class="order-2 sm:order-1">
			<ng-content select="[courses]"></ng-content>
		</div>
		<div class="order-1 sm:order-2">
			<!-- CTA -->
			@if (isFeatured && product !== 'clearing') {
				<uc-cta-university-btns
					[uniId]="id"
					[uniSlug]="slug"
					[product]="product"
					[externalUrl]="externalUrl"
					[externalUgProspectusUrl]="externalUgProspectusUrl ?? ''"
					[externalPgProspectusUrl]="externalPgProspectusUrl ?? ''"
					[isFeaturedUndergraduate]="
						cvSrv.checkFeature(
							clientFeatures,
							clientFeatureOptions.Undergraduate
						)
					"
					[isFeaturedPostgraduate]="
						cvSrv.checkFeature(
							clientFeatures,
							clientFeatureOptions.Postgraduate
						)
					"
					[privacyPolicyUrl]="privacyPolicyUrl"
					[uniName]="name"
					[ugSearchOrderPosition]="ugSearchOrderPosition"
				/>
			}
			@if (isFeatured && product === 'clearing') {
				<uc-cta-clearing-university-btns
					[uniName]="name"
					[uniId]="id"
					[uniSlug]="slug"
					[product]="product"
					[externalUrl]="externalUrl"
					[phoneNumber]="phoneNumber"
					[paidFeatures]="clientFeatures"
				/>
			}
			<!-- End CTA -->
		</div>
	</div>
	<!-- Mobile -->
	@if (displaySeeCoursesBtn) {
		<uc-secondary-blue-btn
			customStyles="w-full mt-4 btn-px-overwrite sm:hidden"
			(buttonClicked)="onViewMoreCourses()"
			[link]="product ? '/courses/' + product : '/courses/undergraduate'"
			[id]="slug"
			[queryParams]="queryParams"
			[buttonText]="source === 'universities' ? 'See Courses' : 'See More Courses'"
		/>
	}
</div>
