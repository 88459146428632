<section class="relative h-48 sm:mt-0 sm:h-full">
	<div class="absolute z-10 sm:hidden top-4 right-4">
		@if (showBookmark) {
			<uc-bookmark
				[saved]="saved"
				[id]="id"
				[delta]="delta"
				[bookmarkHeartIcon]="true"
				[ugSearchOrderPosition]="ugSearchOrderPosition"
				componentName="Profile Header"
				[uniName]="uniName"
				[courseSlug]="courseSlug"
			/>
		}
	</div>
	<!-- Not Featured -->
	@if (!isFeatured && coverImage) {
		<div class="relative h-full mt-5 overflow-hidden sm:mt-0 sm:rounded-l-md">
			<img
				class="object-cover rounded-2xl sm:rounded-l-md sm:rounded-r-none"
				[ngSrc]="coverImage | cdn"
				fill
				alt=""
			/>
		</div>
	}
	<!-- Featured -->
	@if (isFeatured) {
		<div
			id="maincampus"
			class="relative h-full mt-5 overflow-hidden sm:mt-0 sm:rounded-l-md"
		>
			<a
				data-toggle="tab"
				role="tab"
				aria-label="Prev"
				(click)="swipePrev()"
				class="hidden sm:inline-flex absolute gallery-btn-placement z-10 opacity-70 hover:opacity-90 items-center px-5 py-3 border shadow-sm text-sm font-medium rounded-md white-button focus:outline-none mb-1.5 ml-2 cursor-pointer swiper-prev-gallery"
			>
				<uc-arrow-left-svg customStyles="!w-2.5 !h-2.5" />
			</a>
			<a
				data-toggle="tab"
				role="tab"
				aria-label="Next"
				(click)="swipeNext()"
				class="hidden sm:inline-flex absolute gallery-btn-placement right-0 z-10 opacity-70 hover:opacity-90 items-center px-5 py-3 border shadow-sm text-sm font-medium rounded-md white-button focus:outline-none mb-1.5 mr-2 cursor-pointer swiper-next-gallery"
			>
				<uc-arrow-right-svg customStyles="!w-2.5 !h-2.5" />
			</a>

			@if (gallery.images) {
				<swiper-container
					#swiperRef
					init="false"
					class="w-full h-full"
					[effect]="'creative'"
					[creativeEffect]="{
						prev: {
							shadow: true,
							translate: [0, 0, -400],
						},
						next: {
							translate: ['100%', 0, 0],
						},
					}"
				>
					@if (gallery.video?.thumbnail && gallery.video?.url) {
						<swiper-slide>
							<div
								#videoContainer
								ucImpression
								class="relative w-full h-full rounded-2xl"
								(onLostImpression)="pauseVideoIfPlaying()"
								[trackLostImpression]="true"
							>
								<video
									(play)="onVideoPlay()"
									(ended)="onVideoEnded()"
									controls
									controlsList="nodownload"
									disablePictureInPicture
									preload="none"
									[poster]="gallery.video?.thumbnail"
									class="block object-cover !w-full !h-full video-player rounded-2xl sm:rounded-l-md sm:rounded-r-none"
									[ngStyle]="{
										height: videoContainer?.offsetHeight + 'px',
									}"
								>
									<source
										[src]="gallery.video?.url"
										[type]="getVideoFormat(gallery.video?.url ?? '')"
										class="swiper-no-swiping"
									/>
								</video>
							</div>
						</swiper-slide>
					}
					@for (image of gallery.images; track $index) {
						<swiper-slide>
							<div class="relative w-full h-full">
								<img
									loading="lazy"
									class="object-cover rounded-2xl sm:rounded-l-md sm:rounded-r-none"
									alt=""
									[ngSrc]="image?.path | cdn"
									fill
								/>
							</div>
						</swiper-slide>
					}
				</swiper-container>
			}
		</div>
	}
</section>
