import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MinusSvgComponent, PlusSvgComponent } from '@uc/shared/svg';
import { FormErrorComponent } from '@uc/shared/ui';
import { ToggleFaqDirective } from '@uc/utilities';
import { MAX_UCAS_TARIFF } from '@uc/web/shared/data-access';
import { Product, ProductEnum } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-ucas-input',
	standalone: true,
	imports: [
		PlusSvgComponent,
		MinusSvgComponent,
		FormErrorComponent,
		ReactiveFormsModule,
		FormsModule,
		ToggleFaqDirective,
	],
	templateUrl: './ucas-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UcasInputComponent implements OnInit, OnChanges {
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) filterApplied!: boolean;
	@Input({ required: true }) minValue!: number | undefined;
	@Input({ required: true }) maxValue!: number;
	@Input() filterTitle = 'Your UCAS Tariff';

	@Output() setUcasPoints = new EventEmitter<string>();

	readonly maxScore = MAX_UCAS_TARIFF;

	readonly postgraduate = ProductEnum.Postgraduate;

	ucasForm!: FormGroup;
	formSubmitted = false;
	customError = '';

	ngOnInit(): void {
		this.ucasForm = new FormGroup({
			min: new FormControl(null, [
				Validators.min(1),
				Validators.max(this.maxScore - 1),
			]),
			max: new FormControl(null, [
				Validators.min(2),
				Validators.max(this.maxScore),
			]),
		});
		this._patchFormValues();
	}

	ngOnChanges(): void {
		if (this.ucasForm) this._patchFormValues();
	}

	get min() {
		return this.ucasForm.get('min') as FormControl;
	}

	get max() {
		return this.ucasForm.get('max') as FormControl;
	}

	onSubmit(): void {
		this.formSubmitted = true;
		this.customError = '';

		const isFormValid = this._validateUcasInput();
		if (isFormValid) {
			const { min, max } = this.ucasForm.value;
			this.setUcasPoints.emit(`${min}_${max}`);
		}
	}

	private _patchFormValues() {
		if (this.minValue && this.maxValue) {
			this.ucasForm.setValue({
				min: this.minValue,
				max: this.maxValue,
			});
		} else {
			this.ucasForm.reset();
		}
	}

	private _validateUcasInput(): boolean {
		const { min, max } = this.ucasForm.value;

		// don't emit filter if nothing changed
		if (min === this.minValue && max === this.maxValue) {
			this.customError = 'no-changes';
			return false;
		}

		// user has only entered valid MAX value
		if (!min && max && max > 1 && max <= this.maxScore) {
			this.ucasForm.patchValue({ min: 1 });
			return true;

			// user has only entered valid MIN value
		} else if (min && !max && min >= 1 && min <= this.maxScore) {
			this.ucasForm.patchValue({ max: this.maxScore });
			return true;

			// user has not entered any value
		} else if (!min && !max) {
			this.customError = 'required';
			return false;

			// user has entered invalid range
		} else if (min > max) {
			this.customError = 'range';
			return false;

			// user has entered invalid value
		} else if (
			this.min.hasError('min') ||
			this.min.hasError('max') ||
			this.max.hasError('max') ||
			this.max.hasError('min')
		) {
			this.customError = 'invalid';
			return false;
		}

		return true;
	}
}
