import { PromoSlot } from '@uc/web/shared/data-models';

export const addContentToArrays = (
	content: PromoSlot[],
	iterator: number,
	batch: PromoSlot[],
) => {
	for (let i = iterator; batch.length < 4; i++) {
		if (content[i] !== undefined) {
			batch.push(content[i]);
		} else {
			i = -1;
		}
	}
};

export const getRandomPromoSlot = (content: PromoSlot[] | null): null | PromoSlot => {
	if (!content) return null;
	const randomIndex = Math.floor(Math.random() * content.length);
	const slot = {
		...content[randomIndex],
		index: randomIndex,
	};
	return slot;
};
