@if ((promoSlots$ | async) && cards.length) {
	<div class="divider">
		<h2 class="secondary-header">{{ headline }}</h2>

		<div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
			@for (card of cards; track $index) {
				<uc-promo-slot-card
					[card]="card"
					[cardIndex]="$index"
					[section]="section"
					[isInAppBrowser]="isInAppBrowser"
				/>
			}
		</div>
	</div>
}
