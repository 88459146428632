import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { BookmarkModel } from './bookmark.models';

import { BookmarkBtnComponent } from './components/bookmark-btn/bookmark-btn.component';
import { BookmarkHeartIconComponent } from './components/bookmark-heart-icon/bookmark-heart-icon.component';
import { BookmarkService } from './bookmark.service';

export enum BookmarkType {
	University,
	Course,
}

@Component({
	selector: 'uc-bookmark',
	standalone: true,
	templateUrl: './bookmark.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [BookmarkBtnComponent, BookmarkHeartIconComponent],
})
export class BookmarkComponent implements OnInit {
	@Input() ugSearchOrderPosition!: number | null;
	@Input({
		required: true,
		transform: (value: number | boolean | undefined) => Boolean(value),
	})
	saved!: boolean;
	@Input() id: number | undefined;
	@Input() uniName!: string;
	@Input() mobileRender = 'default';
	//if desktop btn style should be shown on mobile too set to true
	@Input() showDesktopOnMobile = false;
	@Input() courseUniProfileMobile = false;
	/**
	 * @delta takes the values 1 | 2
	 * 1 = save university
	 * 2 = save course
	 */
	@Input() delta!: 1 | 2;
	@Input() svgStyles = 'h-6 w-2.5';
	/**
	 * @componentName is used for the analytics
	 */
	@Input() componentName: string | null = null;
	/**
	 * @product is used for the analytics
	 */
	@Input() product: Product | '' = '';
	@Input() bookmarkBtn!: boolean;
	@Input() bookmarkHeartIcon!: boolean;
	@Input() courseSlug = '';

	isSaved = signal(false);

	constructor(private _bookmarkSrv: BookmarkService) {}

	ngOnInit(): void {
		this.isSaved.set(this.saved);
	}

	onBookmark(saved: boolean) {
		const params: BookmarkModel = {
			id: this.id,
			uniName: this.uniName,
			delta: this.delta,
			saved: this.isSaved(),
			componentName: this.componentName,
			product: this.product,
			ugSearchOrderPosition: this.ugSearchOrderPosition,
			courseSlug: this.courseSlug,
		};

		this.isSaved.set(saved);

		this._bookmarkSrv.bookmarkHandler(params).then((response) => {
			if (response === false) {
				this.isSaved.set(!this.isSaved());
			}
		});
	}
}
