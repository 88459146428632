<section class="py-8 border-t bg-gray-50 sm:py-20">
	<div class="flex flex-col items-center max-w-xl px-5 mx-auto text-center">
		<h2 class="mb-2 text-xl font-bold leading-7 sm:leading-10 sm:text-3xl">
			Places at this University are still available through Clearing.
		</h2>
		<p>
			Secure your Clearing {{ currentClearingYear }} space at this uni with a click
			of a button. Call, register your interest or visit the uni's website today.
		</p>
	</div>
	<div class="flex flex-col justify-center gap-3 px-5 mt-8 sm:gap-2 sm:flex-row">
		@if (isFeaturedCalls && phoneNumber) {
			<uc-call-uni-btn
				customStyles="w-full"
				buttonText="Call University"
				mobileButtonText="Call University"
				[phoneNumber]="phoneNumber"
				text="Call University"
				sourceType="course"
				[uniId]="uniId"
				[uniName]="uniName"
				[source]="source"
				componentName="Final CTA Section"
			></uc-call-uni-btn>
		}
		@if (isFeaturedRegisterInterest) {
			<uc-register-interest-btn
				customStyles="w-full"
				sourceType="course"
				[uniId]="uniId"
				componentName="Final CTA Section"
			>
			</uc-register-interest-btn>
		}
		@if (isFeaturedClearingVisitWebsite && externalUrl) {
			<uc-visit-website-btn
				customStyles="w-full"
				[externalUrl]="externalUrl"
				[product]="product"
				componentName="Final CTA Section"
				[uniId]="uniId"
				text="Visit Website"
				[source]="source"
				sourceType="course"
				[uniName]="uniName"
			>
			</uc-visit-website-btn>
		}
	</div>
</section>
